import React from 'react';
import { Table } from 'antd';

 const columns = [
  {
    title: 'Flight',
    dataIndex: 'Flight',
  },
  {
    title: 'Date',
    dataIndex: 'Date',
  },
  {
    title: 'Segment',
    dataIndex: 'Segment',
  },
  {
    title: 'Pieces',
    dataIndex: 'Pieces',      
  },
  {
    title: 'Weight',
    dataIndex: 'Weight',      
  },
  { 
    title: 'Volume',
    dataIndex: 'Volume',      
  },
 
];

let filas = [];


export const TablaReserva = (datos = {}) => {

    const { data } = datos;  

    filas = [
        {
            key: '1',
            Flight: `${data.transportMeans?.carrier.code} - ${data.transportMeans?.transportNumber}`,
            Date: data.transportMeans.date,
            Segment: `${data.onload?.code} - ${data.offload?.code}`,
            Pieces: data.pieces,
            Weight: `${data.weight?.amount}${data.weight?.unit}`,
            Volume:`${data.volume?.amount}${data.volume?.unit}`
        }
    ]
    return(
        <Table
         size="small"
         className='tabla'
         pagination={false}   
         columns={columns} dataSource={filas} 
        />          
    )
}
