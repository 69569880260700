import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { Bookings } from '../../api/Bookings';
import { MostrarBookings } from './MostrarBookings';
import { Spinner } from '../ui/Spinner';
import { ErrorAvailabilityFligth } from './ErrorAvailabilityFligth';


export const ListBookings = () => {

    const [bookings, setBookings ] = useState([]);    
    const [ mostrar, setMostrar] = useState(false);

    const [ showSpinner, setShowSpinner] = useState(false);
    const [ error, setError] = useState(false);

    const onFinish = ({flightDate}) => {

        const url = `/v2/bookings?accountNumbers=00000001116&flightDate=${flightDate}`;
        // const url = `/v2/bookings?accountNumbers=00000001116&airwaybillPrefixes=279&airwaybillSerial=12345678`;
        consultar(url);
        
    };
    
    const consultar = async (url) => {        
        
        try {

            setError(false);
            setShowSpinner(true);
            
            const consulta = await Bookings.get(url);   
            
            if(consulta.status === 200){
                
                setBookings(consulta.data._embedded.bookings);                              
                setShowSpinner(false);
                setMostrar(true);            
            }  
            
        } catch (error) {
            console.log(error)
            setShowSpinner(false);
            setError(true);
        }
        
    }

    useEffect( () => {}, [bookings]);

    return(
        <>
            <Form onFinish={onFinish}  layout="inline"  size='small'>

                <Form.Item
                     label="Flight Date "
                     name="flightDate"                                                         
                >
                    
                    <input type="date"  />

                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{backgroundColor: '#2981C4', color: 'white'}}>
                        Search
                    </Button>
                </Form.Item>

            </Form>

                {
                    (showSpinner) &&  <div> <Spinner /> </div>                                 
                }

                {
                    (error && !showSpinner) && <ErrorAvailabilityFligth />      
                }

                {
                    (mostrar) && <MostrarBookings bookings={bookings}/>
                }
            
        </>
        
        )
        
    }
                
