
import React from 'react'
import { Result } from 'antd';

export const ErrorAvailabilityFligth = () => {
  return (
    <Result
    status='error'
    title='Something Wrong'
    subTitle='Sorry, something went wrong.'
    // extra={<Button type="primary">Back Home</Button>}
  />
  )
}
