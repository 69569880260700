import axios from 'axios'
//  apikey: 'EnbX12j02DDHFrAoqjaq3FIkmTGncrrk',
//  "If-Match": 85854555

export const Bookings = axios.create({
    baseURL: 'https://api-gateway.champ.aero/booking', // cargo spot
    // baseURL: 'https://acsapi-portal.azurewebsites.net/booking',
}); 

export const Update_Cancel_Booking = axios.create({
    baseURL: 'https://acsapi-portal.azurewebsites.net/booking',
    headers: {
        Accept: 'application/json',
        "If-Match": 85854555
    }
})

export const BAgent = axios.create({
    baseURL: 'https://acsapi-portal.azurewebsites.net/acs/forms/v1'
}); 