import React, {useEffect} from 'react';
import { Space, message } from 'antd';


export const MsgError = ({mostrar}) => {

  const [messageApi, contextHolder] = message.useMessage();

  useEffect( () => {

    if(mostrar){
      msgError();
    }

  }, []);

  const msgError = () => {
    messageApi.open({
      type: 'error',
      content: 'Something Wrong'
  });
 }

  return (

    <>
      {contextHolder}
      <Space></Space>
    </>
  )
}


