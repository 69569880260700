import React, {useContext, useState} from 'react';
import { Button, Divider, Form, Input, Select, message } from 'antd'
import { Ejemplo } from '../vuelos/Ejemplo';
import { BtnEnviarReserva } from './BtnEnviarReserva';
import { ReservaContext } from './context/reservaContext';
import { getCurrentDate } from './helpers/fechaActual';
import { AvailabilityObj } from './helpers/VuelosDisponibles';
import { Spinner } from '../ui/Spinner';
import { ApiContext } from '../../context/ApiContext';
import { ErrorAvailabilityFligth } from './ErrorAvailabilityFligth';


const { Option } = Select;


export const Formulario = () => {     

    const [ listado, setlistado ] = useState([]); 
    const [ btnEnviarReserva, setBtnEnviarReserva ] = useState(false); 

    const [ showSpinner, setShowSpinner ] = useState(false); 
    const [ disebleButton, setDisableButton ] = useState(false); 

    const [reserva_init, setReserva_init] = useContext(ReservaContext);       
    const [auth, guardarAuth] = useContext(ApiContext);  

    // funcion para activar el formulario
    const onFinish = async ({ serial, originAirportCode, destinationAirportCode, agents, weight, Date, arrivalOn, 
        natureOfGoods, products, pieces }) => {  
            
        if( originAirportCode === destinationAirportCode){
            message.error('Origin y Dest Are The Same');
            return
        }
           
        setShowSpinner(true);
        setDisableButton(true);

        const consultarDisp = await AvailabilityObj(agents, destinationAirportCode, originAirportCode, weight, Date, auth.token, products);
        setlistado(consultarDisp); 

        setShowSpinner(false);
        setDisableButton(false);
       
        setReserva_init({
            agentAccountNumber: 'UTST001',
            airWaybill: {
                prefix: '279',
                referenceType: 'AIR WAYBILL',
                serial: serial
            },
            destinationAirportCode: destinationAirportCode,
            natureOfGoods: natureOfGoods,
            originAirportCode: originAirportCode,
            pieces: Number(pieces),
            segments: [],
            weight:{ amount:weight, unit: 'KG' },
            agents,
            productCode: products,
            salesAreaCode: 'MIA'
        });    
        
    };   

    return (
        <>
         <div>
          <Form                   
                name="horizontal_login" 
                layout="inline"  
                size='small'              
                onFinish={onFinish}                
                labelCol={{
                    span: 8,
                    }}                    
                    style={{
                        maxWidth: 1100,
                    }}
          >

            <Form.Item  
                label="Awb"                  
                name="airlinePrefix"   
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 6,
                }}            
            >
                <Input 
                    type='text'
                    defaultValue='279'    
                    disabled                                 
                />

            </Form.Item>
                    
                <Form.Item  
                    label="Number" 
                    name="serial" 
                    style={{ marginBottom: '1rem' }}                    
                    wrapperCol={{
                        span: 12,
                    }} 
                    rules={[
                        {len: 8, message: 'AWB must be 8 digits'}
                    ]}
                    hasFeedback                 
                >
                    <Input 
                        type='text'  
                        placeholder='Number'                                         
                    />

                </Form.Item>                
                    
                <Form.Item 
                    label="Origin"
                    name="originAirportCode"
                    wrapperCol={{
                        span: 8,
                    }}  
                    style={{ marginBottom: '1rem' }}    
                    rules={[
                        {
                            required: true,
                            message: 'Please Select',
                        },                       
                    ]}  
                    hasFeedback              
                >
                    <Select
                        style={{
                        width: 130,
                        margin: '0 8px',
                        }}
                    >
                        {
                           
                           Object.entries(auth.origins || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item 
                    label="Dest"
                    name="destinationAirportCode"
                    wrapperCol={{
                        span: 8,
                    }}  
                    style={{ marginBottom: '1rem' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select',
                        }                        
                    ]}
                    hasFeedback
                >

                    <Select
                        style={{
                        width: 130,
                        margin: '0 8px',
                        }}
                    >
                        {
                           
                           Object.entries(auth.destinations || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item 
                    label="Agent"
                    name="agents"
                    wrapperCol={{
                        span: 8,
                    }}  
                    style={{ marginBottom: '1rem' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please Select',
                        }                        
                    ]}
                    hasFeedback
                >

                    <Select
                        style={{
                        width: 130,
                        margin: '0 8px',
                        }}
                    >
                        {
                           
                           Object.entries(auth.agents || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item
                    label='Weight'
                    name='weight' 
                    style={{ marginBottom: '1rem' }}   
                    wrapperCol={{
                        span: 8,
                    }}                                 
                    rules={[
                        {
                            required: true,
                            message: 'Please input weight',                           
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'can only include numbers.',
                        },                   
                       
                    ]}
                    hasFeedback
                >

                <Input 
                    type='text'                     
                    placeholder='LB*'                       
                />

                </Form.Item>

                <Form.Item
                    label="Dep"
                    name="Date"   
                    style={{ marginBottom: '1rem' }}   
                    rules={[
                        { required: true, message: 'Please input'}
                    ]}
                    wrapperCol={{
                        span: 15,
                    }}               
                > 

                <Input 
                    type='date' 
                    min={getCurrentDate()}                       
                />

                </Form.Item>

                <Form.Item
                    label="Arr"
                    name="arrivalOn"   
                    style={{marginBottom: '1rem' }}   
                    wrapperCol={{
                        span: 15,
                    }}               
                > 

                <Input 
                    type='date' 
                    min={getCurrentDate()}                       
                />

                </Form.Item>

                <Form.Item
                    label="OfGoods"
                    name="natureOfGoods" 
                    style={{ marginBottom: '1rem' }}
                    wrapperCol={{
                        span: 8,
                    }} 
                    rules={[
                        {
                            required: true,
                            message: 'Please input',
                        },
                    ]}
                    hasFeedback              
                >

                    <Select
                        style={{
                        width: 130,
                        margin: '0 8px',
                        }}
                    >
                        {
                            // convierto el objeto en un array                           
                           Object.entries(auth.shc).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item
                    label="Product"
                    name="products" 
                    style={{ marginBottom: '1rem' }}
                    wrapperCol={{
                        span: 8,
                    }} 
                    rules={[
                        {
                            required: true,
                            message: 'Please input',
                        },
                    ]}
                    hasFeedback              
                >

                    <Select
                        style={{
                        width: 160,
                        margin: '0 8px',
                        }}
                    >
                        {
                           
                           Object.entries(auth.products).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item
                    label="pieces"
                    name="pieces" 
                    wrapperCol={{
                        span: 8,
                    }}  
                    style={{ marginBottom: '1rem' }}                      
                    rules={[
                        {
                            required: true,
                            message: 'Please input',
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'can only include numbers.',
                        },
                        {max: 3}
                    ]}
                    hasFeedback              
                >

                <Input 
                    type='number'
                    placeholder='pieces'               
                />

                </Form.Item>

                <Form.Item >
                    
                    <Button
                        type="primary"
                        htmlType="submit"     
                        style={{backgroundColor: '#2981C4', color: 'white'}}    
                        disabled={disebleButton}                               
                    >
                        Search Flights
                    </Button>
                
                </Form.Item> 
               
            </Form>          
          
            <Divider />

            {
                (showSpinner) &&  <div> <Spinner /> </div>                                 
            }
           
                {
                         
                    (listado && listado.length > 0 ) ? 

                        <Ejemplo 
                            listado={listado} 
                            reserva={reserva_init}
                            btnEnviarReserva={btnEnviarReserva}
                            setBtnEnviarReserva={setBtnEnviarReserva}
                        />  
                        : (!showSpinner && !listado) && <ErrorAvailabilityFligth />            
                } 

                {
                    (btnEnviarReserva) && <BtnEnviarReserva />
                } 
                
        </div>
    </>
    )
}