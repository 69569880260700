import { useContext, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { Button, Form, Input, Row, Col } from 'antd';
import { TrackAndTrace } from '../../api/TrackAndTrace';
import { DetalleReserva } from './DetalleReserva';
import { ApiContext } from '../../context/ApiContext';
import { Spinner } from '../ui/Spinner';
import { ErrorMsg } from '../Modal/ErrorMsg';


export const Reserva = () => {

    const [ auth, guardarAuth] = useContext(ApiContext);
    const { token } = auth;
    
    const [ booking, setBooking] = useState({});
    const [ showSpinner, setShowSpinner] = useState(false);

    const [ showError, setShowError] = useState(false);
    const {state} = useLocation(); 
    
    const onFinish = ({serialNumber}) => {
        consultar_reserva(serialNumber);    
    };
    const consultar_reserva = async (serialNumber) => {

        setBooking({});

        const params = new URLSearchParams();

        params.append('airlinePrefix', 279);
        params.append('serialNumber', serialNumber);
        params.append('carrierCode', 'B6');

        try {
            setShowError(false);  
            setShowSpinner(true); 
            
            const respuesta = await TrackAndTrace.get('csp/track-and-trace/v1/airwaybill', {params}, {
                headers: {
                    apikey: token
                }
            }); 
            
            const { airwaybill } = respuesta.data;
            setBooking(airwaybill);   

            setShowSpinner(false);         
            
        } catch (error) {
            setShowSpinner(false);     
            setShowError(true);    
            
        }
    }

    if(showSpinner){
        return <Spinner />
    }
      
    return(
        <>     
            {
                (showError) && <ErrorMsg  ver={true}/>
            }  
            <Row gutter={[32, 16]}>
                <Form
                    name="horizontal"
                    layout="inline"
                    size='small'  
                    labelCol={{
                        span: (window.innerWidth > 720 ? 20 : 8),
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}                
                    autoComplete="off"
                >

                 <Col xs={24} sm={16} md={8} lg={6} xl={6}>

                    <Form.Item  
                        label="AWB Number"                  
                        name="airlinePrefix"                       
                        wrapperCol={{
                            span: 10,
                        }}            
                    >
                        <Input 
                            type='text'
                            defaultValue='279'    
                            disabled                                 
                        />

                    </Form.Item>

                </Col>

               <span style={{marginLeft: (window.innerWidth > 720 ? 8 : 15)}}> – </span>

                <Col xs={24} sm={12} md={8} lg={6} xl={8}>

                    <Form.Item  
                        name="serialNumber" 
                        wrapperCol={{
                            span: 20,
                        }}  
                        rules={[
                            {
                                required: true,
                                message: 'Please input AWB Number',
                            },
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Include only numbers.',
                            }, 
                            {len: 8, message: 'AWB must be 8 digits'}
                        ]}
                        hasFeedback                 
                    >
                        <Input  
                            type='text'
                            placeholder='number'                                           
                        />

                     </Form.Item>

                </Col>                
           
                <Form.Item className='btn'>

                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={{backgroundColor: '#1D2758', color: 'white', marginLeft: (window.innerWidth > 720 ? -15 : 10)}} 
                    > 
                        Submit
                    </Button>

                </Form.Item>
              
            </Form>        
        </Row>

            {
               (Object.entries(booking).length > 0) && <DetalleReserva booking={booking} />          
            } 
       </>
    )
}