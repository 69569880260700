import { Availability } from '../../../api/Availability';

export const AvailabilityObj = async ( agent, destination, origin, totalWeight, date, token, products) => {
    
   const params = new URLSearchParams();

   params.append('accountNumber', agent);
   params.append('carrierCodes', 'B6');
   params.append('originAirportCode', origin);
   params.append('destinationAirportCode', destination);
   params.append('departureOn', date);
   params.append('weight', totalWeight);
   params.append('ProductCode', products);
   
    try { 
        const respuesta = await Availability.get('/availability', { params },{ 
             headers: { apikey: token}
            // headers: { apikey: 'EnbX12j02DDHFrAoqjaq3FIkmTGncrrk'} // cargo spot
        });    
                
        return respuesta.data.routes;  
        
    } catch (error) {
        console.log(error);            
        
        return null;        
    }    

}




