
import { BAgent } from '../../../api/Bookings';


export const consultar_agent = async ( token ) => {

    try {

        const respuesta = await BAgent.get('/booking');
        
        return respuesta.data;
        
    } catch (error) {
        console.log(error);        
    }

}