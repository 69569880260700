import axios from 'axios';
// apikey: 'EnbX12j02DDHFrAoqjaq3FIkmTGncrrk',

export const Availability = axios.create({
    // baseURL: 'https://api-gateway.champ.aero/csp/transport-means/v1',
    baseURL: 'https://acsapi-portal.azurewebsites.net/csp/transport-means/v1',
    headers: {
        Accept: 'application/json'
    } 
}); 

