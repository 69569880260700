import React, { useEffect } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

import { Divider, notification, Space } from 'antd';


export const ErrorMsg = ({ver}) => {
  
   const [api, contextHolder] = notification.useNotification();

   useEffect( () => {

    if(ver){
        openNotification();
    }

   }, []);

  const openNotification = () => {

    api.info({
      message: `Awb Number Does Not Exist`,
      icon: (
        <CloseCircleOutlined 
          style={{
            color: '#ff0000',
          }}
        />
      ),
    });

  };

  return (
    
      <>
        {contextHolder}
        <Space>
            </Space>
        <Divider />     
      </>
    
  );
}

 
